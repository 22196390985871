import useSWR, { mutate } from 'swr';
import moment from 'moment';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import Button from '../../../Button';
import { useState } from 'react';
import Modal from '../../../Modal';
import Select from '../../../Select';
import { showNotification } from '../../../../actions';
import { DATE_FORMAT } from '../../../../constants';
import api from '../../../../api/apiClient';

const TreatmentAreas = ({ authToken, showNotification, patient }) => {
  const [selectedArea, setSelectedArea] = useState<CarePlanType | undefined>();
  const [cancellationReason, setCancellationReason] = useState('');
  const [cancellationComment, setCancellationComment] = useState('');
  const [returnMonitor, setReturnMonitor] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const { data: treatmentAreas = [], isLoading } = useSWR<TreatmentArea[]>(
    patient?.guid ? `/careplan/clinic/${patient.guid}` : null
  );

  const cancelTreatment = async () => {
    if (!patient?.guid) {
      return;
    }

    setIsSaving(true);

    try {
      const res = await api.cancelTreatment(
        authToken,
        patient.guid,
        selectedArea,
        cancellationReason,
        cancellationComment,
        returnMonitor
      );

      const json = await res.json();

      if (res.ok) {
        showNotification(json.message, 'success');
      } else {
        showNotification(json.message || I18n.t('error_boundary.header'), 'error');
      }

      setSelectedArea(undefined);
      setCancellationReason('');
      setCancellationComment('');
    } catch {
      showNotification(I18n.t('error_boundary.header'), 'error');
    } finally {
      setIsSaving(false);
    }

    mutate(`/careplan/clinic/${patient.guid}`);
  };

  const resendReturnEmail = async () => {
    if (!patient?.guid) {
      return;
    }

    try {
      const res = await api.resendReturnMonitorEmail(authToken, patient.guid);
      const json = await res.json();

      if (res.ok) {
        showNotification(json.message, 'success');
      } else {
        showNotification(json.message || I18n.t('error_boundary.header'), 'error');
      }
    } catch {
      showNotification(I18n.t('error_boundary.header'), 'error');
    }
  };

  return (
    <>
      <div>
        <h2>
          <Translate value="member_details.treatment_areas.header" />
        </h2>
        {!isLoading && !treatmentAreas.length ? <span>Inga vårdspår</span> : null}
        {treatmentAreas.map((area, i) => (
          <div key={i} className="columns">
            <div className="column ">
              <h4>
                <Translate value={`member_details.treatment_areas.area.${area.carePlan}`} />
              </h4>
              <div>
                <Button
                  buttonType="secondary"
                  onClick={() => setSelectedArea(area.carePlan)}
                  fullWidth
                  disabled={['ABORTING', 'ABORTED'].includes(area.treatmentProcess)}
                >
                  <Translate value="member_details.treatment_areas.cancel_treatment" />
                </Button>
              </div>
            </div>
            <div className="column ">
              <h4>
                <Translate value="global.status" />
              </h4>
              <div className="flex gap-1">
                <Translate value={`member_details.treatment_areas.state.${area.treatmentProcess.toLowerCase()}`} />
                {['ABORTING', 'ABORTED'].includes(area.treatmentProcess) ? (
                  <span>{moment(area.updated, DATE_FORMAT).format('YYYY-MM-DD')}</span>
                ) : null}
              </div>
              {area.carePlan === 'hypertension' && ['ABORTING'].includes(area.treatmentProcess) ? (
                <Button buttonType="text" onClick={resendReturnEmail}>
                  <Translate value="member_details.treatment_areas.resend_return_monitor_email" />
                </Button>
              ) : null}
            </div>
          </div>
        ))}
      </div>
      <Modal
        header={I18n.t('member_details.treatment_areas.modal_header', {
          treatmentArea: I18n.t(`member_details.treatment_areas.area.${selectedArea}`)
        })}
        actionI18nKey="global.buttons.send"
        visible={!!selectedArea}
        onClose={() => {
          setSelectedArea(undefined);
          setCancellationReason('');
        }}
        onActionCompleted={cancelTreatment}
        actionCompletable={true}
        actionCompleting={isSaving}
        size="small"
      >
        <div className="stack-1">
          <div>
            <Translate value="member_details.treatment_areas.reason_header" />
          </div>
          <Select
            title={I18n.t('member_details.treatment_areas.prepopulated')}
            placeholder={I18n.t('member_details.treatment_areas.choose_reason')}
            isSearchable={false}
            onChange={(option: SelectOption) => {
              if (!option.value) {
                return;
              }

              setCancellationReason(option.value);
              setCancellationComment(option.label);
            }}
            classNamePrefix="single-select"
            options={treatmentAreas?.find((x) => x.carePlan === selectedArea)?.abortOptions ?? []}
          />
          <textarea
            id="treatment-area-cancellation-reason"
            style={{ height: '200px' }}
            value={cancellationComment}
            onChange={(e) => setCancellationComment(e.target.value)}
          />
          {selectedArea === 'hypertension' ? (
            <div>
              <input
                type="checkbox"
                id="return-monitor-checkbox"
                checked={returnMonitor}
                onChange={(e) => setReturnMonitor(e.target.checked)}
              />
              <label htmlFor="return-monitor-checkbox">
                <Translate value="member_details.treatment_areas.patient_returns_monitor" />
              </label>
            </div>
          ) : null}
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    patient: state.members.currentMember
  };
};

const mapActionsToProps = {
  showNotification
};

export default connect(mapStateToProps, mapActionsToProps)(TreatmentAreas);
